import store from '@/store';

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/Directive.vue
 */
 export default function checkThePermissions(value) {
  if(store.getters.getRoles !=null){
    return checkPermissions(value);
  }
  return false;
}

/**
 * check for the Permissions - we only need this function because as the export default we can not invoke the default-function as a function
 * @param  {[type]} value [description]
 * @return {[type]}       [description]
 */
 export function checkPermissions(value) {
  //const roles = store.getters && store.getters.getRoles;
  var values = null;

  /*to allow for multiple permissions we first check if the value is an array - which means multiple values - or a single value*/
  if(value && !(value instanceof Array)){
    /*push the value onto an array*/
    values = [];
    values.push(value);
  }
  /*else if it is an array and the array is not empty*/
  else if(value && value instanceof Array && value.length > 0){
    values = value;
  }

  if (values != null) {
    /*iterate through the values array that we should have at this point*/
    for(var i=0;i<values.length;i++){ const="" permissionObject="values[i];" var="" hasPermission="false;" *check="" if="" each="" permission="" is="" correctly="" formend="" -="" meaning="" an="" element="" with="" apps,="" collections="" or="" institution="" given="" so="" we="" can="" check="" for="" specific="" permissions*="" if(Object.prototype.hasOwnProperty.call(permissionObject,'permission')="" &&="" permissionObject.permission="" !="null){" *the="" are="" looking="" for*="" *as="" always="" let's="" start="" the="" system-level*="" *is="" in="" permissionOBject?="" yes="" we'll="" use="" it="" to="" against="" permissions="" of="" this="" user*="" if(Object.prototype.hasOwnProperty.call(permissionObject,'elem')="" permissionObject.elem="" all="" connections="" against*="" permElem="permissionObject.elem;" *look="" apps*="" if(!hasPermission){="" }="" institutions*="" collections*="" *if="" true="" return="" value.="" If="" not="" continue*="" if(hasPermission="=" true){="" hasPermission;="" *we="" at="" end="" loop="" whatever="" have="" point="" which="" no="" hit="" a="" permission.*="" false;="" console.error(`Need="" permissions!="" Like="" v-permission="['manage permission','edit article']" `);="" **="" *="" Permission="" System="" @param="" {[type]}="" [description]="" @return="" export="" function="" checkSystemPermission(permission)="" {="" *first="" step="" get="" system="" roles="" from="" store*="" store.getters.getRoles;="" if(!Object.prototype.hasOwnProperty.call(roles,'systemRoles')="" ||="" (Object.prototype.hasOwnProperty.call(roles,'systemRoles')="" roles.systemRoles="=" null)){="" user="" has="" role="" app*="" if(Object.prototype.hasOwnProperty.call(roles.systemRoles,'permissions')="" roles.systemRoles.permissions!="null){" appRole="" certain="" permission*="" ==""> {
      return rolePermission.technical_name == permission;
    }); 
    return hasPermission;
  }
  return false;
}

/**
 * check if the user has a permission for a certain app
 * @param  {[type]} permission [description]
 * @param  {[type]} app_id     [description]
 * @return {[type]}            [description]
 */
 export function checkAppPermission(permission, app_id) {
  if (app_id) {
    var hasPermission = false;

    /*first step - get the app-roles from the roles in the store*/
    const roles = store.getters && store.getters.getRoles;
    if(!Object.prototype.hasOwnProperty.call(roles,'appRoles') || (Object.prototype.hasOwnProperty.call(roles,'appRoles') && (roles.appRoles == null || roles.appRoles.length==0))){
      return false;
    }

    var appRoles = roles.appRoles;

    /*look if the user has a role for a specific app*/
    for(var i = 0; i < appRoles.length; i++){
      if(appRoles[i].app_id == app_id){
        /*if the current user has a role for the given app*/
        var appRole = appRoles[i].role;
        /*check in the permission of the appRole if the user has a certain permission*/
        hasPermission = appRole.permissions.some(rolePermission => {
          return rolePermission.technical_name == permission;
        }); 
        return hasPermission;
      }
    }
  } 
  return false;
}

/**
 * check if the current user has a certain permission for a certain institution
 * @param  {[type]} permission [description]
 * @param  {[type]} app_id     [description]
 * @return {[type]}            [description]
 */
 export function checkInstitutionPermission(permission, institution_id) {
  if (institution_id) {
    var hasPermission = false;

    /*first step - get the app-roles from the roles in the store*/
    const roles = store.getters && store.getters.getRoles;
    if(!Object.prototype.hasOwnProperty.call(roles,'institutionRoles') || (Object.prototype.hasOwnProperty.call(roles,'institutionRoles') && (roles.institutionRoles == null || roles.institutionRoles.length==0))){
      return false;
    }

    var institutionRoles = roles.institutionRoles;

    /*look if the user has a role for a specific app*/
    for(var i = 0; i < institutionRoles.length; i++){
      if(institutionRoles[i].institution_id == institution_id){
        /*if the current user has a role for the given app*/
        var institutionRole = institutionRoles[i].role;
        /*check in the permission of the appRole if the user has a certain permission*/
        hasPermission = institutionRole.permissions.some(institutionPermission => {
          var a = institutionPermission.technical_name == permission;
          return a;
        }); 
        return hasPermission;
      }
    }
  } 

  return false;
}

/**
 * check the permission of this user for a certain Collection
 * @param  {[type]} permission    [description]
 * @param  {[type]} collection_id [description]
 * @return {[type]}               [description]
 */
 export function checkCollectionPermission(permission, collection_id) {
  if (collection_id) {
    var hasPermission = false;

    /*first step - get the app-roles from the roles in the store*/
    const roles = store.getters && store.getters.getRoles;
    if(!Object.prototype.hasOwnProperty.call(roles,'collectionRoles') || (Object.prototype.hasOwnProperty.call(roles,'collectionRoles') && (roles.collectionRoles == null || roles.collectionRoles.length==0))){
      return false;
    }

    var collectionRoles = roles.collectionRoles;

    /*look if the user has a role for a specific app*/
    for(var i = 0; i < collectionRoles.length; i++){
      if(collectionRoles[i].collection_id == collection_id){
        /*if the current user has a role for the given app*/
        var collectionRole = collectionRoles[i].role;
        /*check in the permission of the appRole if the user has a certain permission*/
        hasPermission = collectionRole.permissions.some(collectionPermission => {
          return collectionPermission.technical_name == permission;
        }); 
        return hasPermission;
      }
    }
  } 
  return false;
}

/*just a little compentmarlisation to look for the permission in the apps*/
export function checkForApps(permission, elem){
  var hasPermission = false;

  /*next step - look in the permElem if there are apps that are connected with this element but only if the hasPermission is still false!*/
  if(Object.prototype.hasOwnProperty.call(elem,'apps') && elem.apps != null && elem.apps.length>0){
    /*we are assuming apps is an array of multiple apps - that means we have to iterate through all apps*/
    for(var i = 0; i < elem.apps.length; i++){
      /*check for the permission using the checkAppPermissionMethod from utils*/
      hasPermission = checkAppPermission(permission, elem.apps[i].id);
      /*look if the hasPermission is true - if that's the case we can continue*/
      if(hasPermission == true){
        continue;
      }
    }
  }

  /*i know a bit bewildering but maybe there are elements that are directly connected to a specific app and therefore the element doesn't have apps but a single-app variable? - better check for that too*/
  if(hasPermission == false && Object.prototype.hasOwnProperty.call(elem,'app') && elem.app != null){
    hasPermission = checkAppPermission(permission, elem.app.id);
  }

  if(hasPermission == false && Object.prototype.hasOwnProperty.call(elem,'app_id') && elem.app_id != null){
    hasPermission = checkAppPermission(permission, elem.app_id);
  }

  /*maybe the element itself is an app?*/
  if(Object.prototype.hasOwnProperty.call(elem,'type') && elem.type=="app"){
    hasPermission = checkAppPermission(permission, elem.id);    
  }

  return hasPermission;
}


/*just a little compentmarlisation to look for Institutions*/
export function checkForInstitutions(permission, elem){
  var hasPermission = false;

  /*we repeat what we have done with the apps for the institutions - first we look for multiple institutions that are connected to a certain elem*/
  if(Object.prototype.hasOwnProperty.call(elem,'institutions')  && elem.institutions != null && elem.institutions.length>0){
    /*we are assuming institutions is an array of multiple institutions - that means we have to iterate through all institutions*/
    for(var i = 0; i < elem.institutions.length; i++){
      /*check for the permission using the checkInstitutionPermissionMethod from utils*/
      hasPermission = checkInstitutionPermission(permission, elem.institutions[i].id);
      /*look if the hasPermission is true - if that's the case we can continue*/
      if(hasPermission == true){
        continue;
      }
    }
  }

  /*look for a single institution_id*/
  if(hasPermission == false && Object.prototype.hasOwnProperty.call(elem,'institution_id')  && elem.institution_id != null){
    hasPermission = checkInstitutionPermission(permission, elem.institution_id);
  }

  /*look for a single institution*/
  if(hasPermission == false && Object.prototype.hasOwnProperty.call(elem,'institution') && elem.institution != null){
    hasPermission = checkInstitutionPermission(permission, elem.institution.id);
  }

  /*maybe the element itself is an institution?*/
  if(hasPermission == false && Object.prototype.hasOwnProperty.call(elem,'type')  && elem.type=="institution"){
    hasPermission = checkInstitutionPermission(permission, elem.id);    
  }

  return hasPermission;
}

/*look for the permission in collections if there are any given*/
export function checkForCollections(permission, elem){
  var hasPermission = false;

  /*we repeat what we have done with the apps and the institutions - first we look for multiple collections that are connected to a certain elem*/
  if(Object.prototype.hasOwnProperty.call(elem,'collections')  && elem.collections != null && elem.collections.length>0){
    /*we are assuming collections is an array of multiple collections - that means we have to iterate through all collections*/
    for(var i = 0; i < elem.collections.length; i++){
      /*check for the permission using the checkCollectionPermissionMethod from utils*/
      hasPermission = checkCollectionPermission(permission, elem.collections[i].id);
      /*look if the hasPermission is true - if that's the case we can continue*/
      if(hasPermission == true){
        continue;
      }
    }
  }

  /*look for a single collection*/
  if(hasPermission == false && Object.prototype.hasOwnProperty.call(elem,'collection')  && elem.collection != null){
    hasPermission = checkCollectionPermission(permission, elem.collection.id);
  }

  if(hasPermission == false && Object.prototype.hasOwnProperty.call(elem,'collection_id')  && elem.collection_id != null){
    hasPermission = checkCollectionPermission(permission, elem.collection_id);
  }

  /*maybe the element itself is an app?*/
  if(Object.prototype.hasOwnProperty.call(elem,'type')  && elem.type=="collection"){
    hasPermission = checkCollectionPermission(permission, elem.id);    
  }

  return hasPermission;
}
</values.length;i++){>